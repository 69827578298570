import {
  MedicationOrderBoxStatus,
  MedicationOrderStatus,
} from "@/interfaces/enum/medicationOrderStatus";
import { Conversation } from "@/interfaces/conversation";
import { BoxWithOrders, Message } from "@/interfaces/message";
import { useSessionStore } from "@/store/sessionStore";
import { MessageType } from "@/interfaces/enum/messageType";
import { formatDate, localeDateString } from "./datehelper";

export function statusOfConversation(
  conversation: Conversation,
  allMessages: Message[]
): {
  label: string;
  color: string | null;
  progress: number;
} {
  if (!conversation.orders_sent) {
    return {
      label: "Entwurf",
      color: "darkorange",
      progress: 5,
    };
  }

  const messages = Array.from(allMessages?.values() ?? []).filter(
    (message) =>
      (message.medication_box != null || message.medication_order != null) &&
      message.conversation.id == conversation.id &&
      !message.medication_order?.deleted &&
      !message.medication_box?.deleted
  );
  if (messages.length == 0) {
    return {
      label: "Leer",
      color: "red",
      progress: 4,
    };
  }
  let didStart = false;
  let didFinishAll = true;

  for (const message of messages) {
    if (message.medication_box) {
      const status = MedicationOrderBoxStatus.find(
        (status) => status.id == message.medication_box?.status ?? 0
      );
      if (status == null || status.progress == 0) {
        didFinishAll = false;
      } else if (status.progress > 0) {
        didStart = true;
        if (status.progress <= 2) {
          didFinishAll = false;
        }
      }
    }

    if (message.medication_order) {
      const status = MedicationOrderStatus.find(
        (status) => status.id == message.medication_order?.status ?? 0
      );
      if (status == null || status.progress == 0) {
        didFinishAll = false;
      } else if (status.progress > 0) {
        didStart = true;
        if (status.progress <= 2 || message.medication_order.box == null) {
          didFinishAll = false;
        }
      }
    }
    if (!didFinishAll && didStart) {
      return {
        label: "In Bearbeitung",
        color: "orange",
        progress: 1,
      };
    }
  }
  if (didFinishAll) {
    return {
      label: "Abgeschlossen",
      color: "green",
      progress: 2,
    };
  }
  return {
    label: "Offen",
    color: "blue",
    progress: 0,
  };
}

export function newChatMessages(
  conversation: Conversation,
  messages: Message[]
): number {
  const sessionStore = useSessionStore();
  const filteredMessages = messages.filter(
    (message) =>
      message.conversation.id == conversation.id &&
      message.type == 0 &&
      message.id > (conversation.read ?? 0) &&
      message.sender_care_provider_group_id !=
        sessionStore.selectedCareProviderGroup?.id
  );
  if (
    conversation.senderCareProviderGroup.id !=
      sessionStore.selectedCareProviderGroup?.id &&
    (conversation.read ?? 0) == 0 &&
    filteredMessages.length == 0
  ) {
    return 1;
  }
  return filteredMessages.length;
}

export function getDeliveryStatusOfConversation(messages: Message[]) {
  const status = messages.findLast(
    (message) => message.type == MessageType.deliveryStatus
  );
  if (status && status.delivery_status?.date) {
    return {
      label: `Lieferdatum: ${localeDateString(
        status.delivery_status?.date ?? ""
      )}`,
      date: "",
      desc: `${status.delivery_status?.date}`,
    };
  }
  return undefined;
}

export function isBoxOverdue(message: BoxWithOrders, date?: Date): boolean {
  if (!message.due) return false;
  const inputDate = new Date(message.due);
  const currentDate = date ?? new Date();
  currentDate.setHours(0, 0, 0, 0);
  return inputDate < currentDate;
}

export function isBoxDue(message: BoxWithOrders, date?: Date): boolean {
  if (!message.due) return false;
  const inputDate = new Date(message.due);
  const currentDate = date ?? new Date();
  currentDate.setHours(23, 59, 0, 0);
  if (date) {
    // if date selected hide overdue
    return formatDate(inputDate) == formatDate(currentDate);
  } else {
    return inputDate <= currentDate;
  }
}
