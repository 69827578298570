import {
  CareProviderGroup,
  CareProviderGroupKey,
} from "@/interfaces/careProviderGroup";
import { Profile } from "@/interfaces/profile";
import { supabase } from "@/helpers/supabase";
import { hexToBytes } from "@/helpers/encryption";
import { defineStore } from "pinia";
import { useSessionStore } from "./sessionStore";

export interface PublicState {
  careProviderGroups: CareProviderGroup[] | null;
  profiles: Profile[] | null;
  careProviderGroupKeys: Map<number, CareProviderGroupKey> | null;
}

export const usePublicStore = defineStore("public", {
  state: (): PublicState => ({
    careProviderGroups: null,
    careProviderGroupKeys: null,
    profiles: null,
  }),
  getters: {
    oppositeGroups(state) {
      const selectedCareProviderGroup =
        useSessionStore().selectedCareProviderGroup;
      return state.careProviderGroups?.filter((cpg) => {
        if (cpg.id == selectedCareProviderGroup?.id) {
          return false;
        }
        if (cpg.is_orderer && selectedCareProviderGroup?.is_orderer) {
          return false;
        }
        if (cpg.is_shop && selectedCareProviderGroup?.is_shop) {
          return false;
        }
        if (!cpg.is_shop && !cpg.is_orderer) {
          return false;
        }
        return true;
      });
    },
  },
  actions: {
    async loadPublicStore() {
      const loadPublicCareProviderGroups = this.loadPublicCareProviderGroups();

      const loadPublicCareProviderGroupKeys =
        this.loadPublicCareProviderGroupKeys();

      const loadProfiles = this.loadProfiles();
      return await Promise.all([
        loadPublicCareProviderGroups,
        loadPublicCareProviderGroupKeys,
        loadProfiles,
      ]);
    },
    careProviderGroupWithId(id: number): CareProviderGroup | undefined {
      if (!this.careProviderGroups) return;
      const cpg = this.careProviderGroups.find((cpg) => cpg.id == id);
      return cpg;
    },
    async loadPublicCareProviderGroups() {
      try {
        const result = await supabase.from("careProviderGroup").select("*");
        if (result.error != null) {
          console.error("ERROR LOADING Public CPGs ", result.error);
          return;
        }

        this.careProviderGroups = result.data;
      } catch (e) {
        console.error("WHAT?", e);
      }
    },
    async loadProfiles() {
      try {
        const result = await supabase
          .from("profile")
          .select("*")
          .returns<Profile[]>();
        if (result.error != null) {
          console.error("ERROR LOADING (Public) CPs ", result.error);
          return;
        }

        this.profiles = result.data;
      } catch (e) {
        console.error("WHAT?", e);
      }
    },
    latestPublicKeyOfGroup(groupId: number): CareProviderGroupKey | null {
      for (const key of this.careProviderGroupKeys?.values() ?? []) {
        if (key.cpgId == groupId) {
          return key;
        }
      }
      return null;
    },
    async loadPublicCareProviderGroupKeys() {
      const result = await supabase
        .from("careProviderGroup_publicKey")
        .select("id, care_provider_group_id, public_key")
        .order("id", { ascending: false });
      if (result.error != null || result.data == null) {
        console.error("ERROR LOADING Public CPG-Keys ", result.error);
        return;
      }

      const map: Map<number, CareProviderGroupKey> = new Map();
      for (const row of result.data) {
        map.set(row.id, {
          key: hexToBytes(row.public_key),
          keyId: parseInt(row.id),
          cpgId: row.care_provider_group_id,
        });
      }

      this.careProviderGroupKeys = map;
    },
  },
});
