import {
  bytesToHex,
  decryptCareProviderGroupKey,
  hexToBytes,
} from "@/helpers/encryption";
import { CareProviderGroupKey } from "@/interfaces/careProviderGroup";
import { supabase } from "@/helpers/supabase";
import { defineStore } from "pinia";
import { useNotificationStore } from "./notificationStore";
import { useSessionStore } from "./sessionStore";
import { KeyPair } from "libsodium-wrappers";

interface AccountState {
  careProviderGroups: any[] | null;
  firstName: string | null;
  lastName: string | null;
  loggedIn: boolean;
  careProviderGroupPrivateKeys: Map<number, CareProviderGroupKey> | null;
  loadedProfile: boolean;
}

export const useAccountStore = defineStore("account", {
  state: (): AccountState => ({
    careProviderGroups: null,
    firstName: null,
    lastName: null,
    loggedIn: false,
    careProviderGroupPrivateKeys: null,
    loadedProfile: false,
  }),
  getters: {},

  actions: {
    setProfile(obj: any | null) {
      if (obj == null) return;
      this.firstName = obj.first_name;
      this.lastName = obj.last_name;
    },
    addCareProviderGroupKey({
      id,
      key,
    }: {
      id: number;
      key: CareProviderGroupKey;
    }) {
      if (this.careProviderGroupPrivateKeys == null) {
        this.careProviderGroupPrivateKeys = new Map();
      }
      this.careProviderGroupPrivateKeys.set(id, key);
    },

    async loadProfile(keyPair?: KeyPair, savePassword?: boolean) {
      const sessionStore = useSessionStore();

      const result = await supabase
        .from("profile_publicKey")
        .select(
          `
          public_key,
          salt,
          id,
          profile (
            first_name,
            last_name,
            id,
            color
          )
        `
        )
        .eq("user_id", sessionStore.session?.user.id);

      if (result.error != null) {
        useNotificationStore().notifications.push({
          msg: `Fehler - Profil wurde nicht geladen. #pr01: ${result.error}`,
          type: "danger",
        });
        this.setProfile(null);
        return;
      }

      if (result.data?.length == 0) {
        this.setProfile(null);
        this.loadedProfile = true;
      } else {
        if (keyPair) {
          if (bytesToHex(keyPair.publicKey) != result.data?.[0]?.public_key) {
            sessionStore.keyPair = null;
            return;
          } else {
            sessionStore.keyPair = keyPair;
          }
        } else if (sessionStore.password != null) {
          await sessionStore.calculateKeyPair(
            sessionStore.password,
            result.data?.[0]?.salt,
            result.data?.[0]?.public_key,
            savePassword
          );
        }

        this.setProfile(result.data?.[0]?.profile);
        this.loadedProfile = true;
        sessionStore.salt = result.data?.[0]?.salt;
        sessionStore.correctPublicKey = result.data?.[0]?.public_key;
        sessionStore.publicKeyId = result.data?.[0]?.id;
      }
    },
    async loadCpgs() {
      const sessionStore = useSessionStore();

      const result = await supabase
        .from("careProvider_careProviderGroup")
        .select(
          `
          care_provider_group_id,
          encrypted_private_key,
          private_key_id,
          used_public_key_id,
          careProviderGroup (
            id,
            group_name,
            owner,
            short_name,
            color,
            address1,
            address2,
            city,
            zip, 
            is_orderer,
            is_shop
          )
        `
        )
        .eq("care_provider_id", sessionStore.session?.user.id);
      if (result.error != null) {
        console.error("ERROR LOADING CPGs ", result.error);
        return;
      }

      for (const careProviderGroup of result.data) {
        if (sessionStore.keyPair != null) {
          this.addCareProviderGroupKey({
            key: {
              key: await decryptCareProviderGroupKey(
                sessionStore.keyPair?.publicKey,
                sessionStore.keyPair?.privateKey,
                hexToBytes(careProviderGroup.encrypted_private_key)
              ),
              cpgId: careProviderGroup.care_provider_group_id,
              keyId: careProviderGroup.private_key_id,
            },
            id: careProviderGroup.private_key_id,
          });
        }
      }

      if (sessionStore.selectedCareProviderGroup) {
        for (const entry of this.careProviderGroupPrivateKeys ?? []) {
          if (entry[1].cpgId == sessionStore.selectedCareProviderGroup.id) {
            sessionStore.selectedCareProviderGroupKeys.set(entry[0], entry[1]);
          }
        }
      }

      this.careProviderGroups = result.data.filter(
        (value, index, self) =>
          index ===
          self.findIndex(
            (t) => t.care_provider_group_id === value.care_provider_group_id
          )
      );
    },
  },
});
