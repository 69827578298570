import { supabase } from "@/helpers/supabase";
import { RealtimeChannel } from "@supabase/supabase-js";
import { useSessionStore } from "./sessionStore";
import { defineStore } from "pinia";

import { usePublicStore } from "./publicStore";
import { useAccountStore } from "./accountStore";
import { useOrdersStore } from "./ordersStore";
import { useManagedPatientsStore } from "./managedPatientsStore";
import { useNotificationStore } from "./notificationStore";
import { useMedicationsStore } from "./medicationsStore";

interface RealTimeSubscriptionState {
  realTimeChannel: RealtimeChannel | null;
  working: boolean;
}

export const useRealTimeSubscriptionStore = defineStore(
  "realTimeSubscription",
  {
    state: (): RealTimeSubscriptionState => ({
      realTimeChannel: null,
      working: false,
    }),
    actions: {
      async startSubscription() {
        await this.realTimeChannel?.unsubscribe();
        this.working = true;
        this.realTimeChannel = supabase
          .channel("realTimeChannel")
          .on(
            "postgres_changes",
            {
              event: "*",
              schema: "public",
            },
            (payload) => {
              const newEvent = payload.new as { [key: string]: any };
              const managedPatientsStore = useManagedPatientsStore();

              if (payload.table == "managedPatient_data") {
                const managedPatientsStore = useManagedPatientsStore();
                managedPatientsStore.updatePatients(
                  newEvent["managed_patient_id"] as number
                );
                return;
              }
              if (payload.table == "managedPatient") {
                const managedPatientsStore = useManagedPatientsStore();
                managedPatientsStore.updatePatients(newEvent["id"]);
                return;
              }

              if (payload.table == "careProviderGroup_managedPatient") {
                if (payload.eventType == "DELETE") {
                  managedPatientsStore.patientsMap?.delete(
                    payload.old.managed_patient_id
                  );
                  return;
                }
                managedPatientsStore.updatePatients(
                  newEvent["managed_patient_id"]
                );
                return;
              }

              if (payload.table == "managedPatient_deletedFiles") {
                useManagedPatientsStore().updatePatients(
                  newEvent["managed_patient_id"]
                );
              }
              const ordersStore = useOrdersStore();

              if (payload.table == "orders_message") {
                ordersStore.updateMessages(newEvent["id"]);
              }
              if (
                payload.table == "orders_conversation" ||
                payload.table == "orders_conversation_status"
              ) {
                ordersStore.updateConversations(newEvent["conversation_id"]);
              }
              if (payload.table == "orders_sent") {
                ordersStore
                  .updateConversations(newEvent["conversation_id"])
                  .finally(() => {
                    ordersStore.updateMessages(
                      undefined,
                      newEvent["conversation_id"]
                    );
                  });
              }
              if (
                payload.table == "careProviderGroup" ||
                payload.table == "careProviderGroup_publicKey" ||
                payload.table == "profile"
              ) {
                usePublicStore().loadPublicStore();
              }

              if (
                payload.table == "careProviderGroup" ||
                payload.table == "profile"
              ) {
                usePublicStore().loadPublicStore();
              }
              if (payload.table == "careProvider_careProviderGroup") {
                useAccountStore().loadCpgs();
              }
              if (payload.table == "careProviderGroup_favorites") {
                useSessionStore().loadFavorites();
              }
              if (
                payload.table == "shop_medication_information" ||
                payload.table == "medication_index"
              ) {
                useMedicationsStore().reset();
              }
            }
          )
          .subscribe((callback) => {
            if (callback == "CLOSED") {
              console.log("CALLBACK: ", callback);
              useNotificationStore().notifications =
                useNotificationStore().notifications.filter(
                  (notification) => notification.customId != "callback"
                );
              useNotificationStore().notifications.push({
                msg: `Ihre Internetverbindung ging verloren. Bitte stellen Sie wieder eine Internetverbindung her und laden Sie anschliessend die neusten Änderungen herunter. (${callback})`,
                type: "warning",
                icon: "refresh",
                customId: "callback",
                keepAfterPageChange: true,
                action: async () => {
                  const sessionStore = useSessionStore();
                  if (sessionStore.selectedCareProviderGroup) {
                    await sessionStore.setSelectedCareProviderGroup(
                      sessionStore.selectedCareProviderGroup
                    );
                  }
                  useNotificationStore().notifications =
                    useNotificationStore().notifications.filter(
                      (notification) => notification.customId != "callback"
                    );
                },
              });
            }
          });
      },
    },
  }
);
