import {
  createRouter,
  createWebHistory,
  RouteRecordRaw,
  RouterView,
} from "vue-router";
import ManagedPatientsView from "@/modules/Patients/views/ManagedPatientsView.vue";
import ManagedPatientView from "@/modules/Patients/views/ManagedPatientView.vue";
import OrdersConversationsView from "@/modules/Orders/views/OrdersConversationsView.vue";
import OrdersConversationView from "@/modules/Orders/views/OrdersConversationView.vue";
import OrdersDeliveryView from "@/modules/Orders/views/OrdersDeliveryView.vue";
import OrdersInvoiceView from "@/modules/Orders/views/OrdersInvoiceView.vue";
import SettingsView from "@/modules/Settings/views/SettingsView.vue";
import ManagedPatientsBulkImportViewVue from "@/modules/Patients/views/ManagedPatientsBulkImportView.vue";
import { useNotificationStore } from "@/store/notificationStore";
import { h } from "vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "home",
    component: SettingsView,
  },
  {
    path: "/conversations",
    component: { render: () => h(RouterView) },
    children: [
      {
        path: "",
        name: "conversations",
        component: OrdersConversationsView,
      },
      {
        path: ":id",
        name: "conversation",
        component: OrdersConversationView,
      },
    ],
  },
  {
    path: "/supplier",
    name: "supplier",
    component: OrdersDeliveryView,
  },
  {
    path: "/managedPatients",
    component: { render: () => h(RouterView) },
    children: [
      {
        path: "",
        name: "managedPatients",
        component: ManagedPatientsView,
      },
      {
        path: "bulk",
        name: "managedPatientsBulkImport",
        component: ManagedPatientsBulkImportViewVue,
      },
      {
        path: ":id",
        name: "managedPatient",
        component: ManagedPatientView,
      },
    ],
  },
  {
    path: "/invoice",
    name: "Abrechnung",
    component: OrdersInvoiceView,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from) => {
  useNotificationStore().notifications =
    useNotificationStore().notifications.filter(
      (notification) => notification.keepAfterPageChange
    );
});

export default router;
