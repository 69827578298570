import { de } from "@formkit/i18n";
import { createInput, DefaultConfigOptions } from "@formkit/vue";

import { generateClasses } from "@formkit/themes";
import { genesisIcons } from "@formkit/icons";
import tailwindTheme from "./tailwind-theme";
import MultiSelectVue from "./components/MultiSelect.vue";
import MedicationSelectVue from "./components/MedicationSelect.vue";
import NumberInputVue from "./components/NumberInput.vue";

const multiselect = createInput(MultiSelectVue, {
  props: [
    "selectOptions",
    "searchable",
    "trackBy",
    "optionLabel",
    "customLabel",
    "allowEmpty",
    "reset",
    "placeholder",
    "afterList",
    "onAfterList",
    "multiple",
  ],
});

const medicationSelect = createInput(MedicationSelectVue, {
  props: ["patient", "disableCustom", "shopCareProviderGroupId"],
});

const numberInput = createInput(NumberInputVue, {
  props: ["min", "max"],
});

const formkitConfig: DefaultConfigOptions = {
  locales: { de },
  locale: "de",
  icons: {
    ...genesisIcons,
  },
  config: {
    classes: generateClasses(tailwindTheme),
  },
  inputs: {
    multiselect: multiselect,
    medicationselect: medicationSelect,
    numberinput: numberInput,
  },
};

export default formkitConfig;
